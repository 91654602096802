.ui-steps.steps-crupdate-agent .ui-steps-item {
	width: 33.333333%;
}

.ui-steps.booking-crupdate-steps .ui-steps-item {
	width: 16.666666666%;
}

.ui-steps {
	margin-top: 15px;
	margin-bottom: 50px;
}

.ui-steps .ui-steps-item .ui-menuitem-link {
	height: 10px;
	padding: 0 1em;
}

.ui-steps .ui-steps-item .ui-steps-number {
	background-color: #c2a500;
	color: #FFFFFF;
	display: inline-block;
	width: 36px;
	border-radius: 50%;
	margin-top: -14px;
	margin-bottom: 10px;
}

.ui-steps .ui-steps-item .ui-steps-title {
	color: #ceb289;
}
/* .ui-panel.ui-widget {
	margin-top: 20px;
} */
.ui-panel .ui-widget-header, .ui-panel-header {
	background: linear-gradient(to bottom, #584620 0%, #b39660 100%) !important;
	border-color: #584620;
}
.ui-widget-content {
	border: 1px solid #ece0ab;
}
dl, h5 {
	margin: 0;
	padding: 0;
}
dt {
	font-weight: 700;
}
dd {
	margin-left: 0;
	margin-top: .5rem;
	word-wrap: break-word;
}
.ui-table table {
	table-layout: auto;
}
.ui-panel-footer {
	background-color: #b39660;
}
.ui-panel-footer button, .ui-panel-footer span {
	padding: .5em;
}
.ui-panel-footer .ui-button, .ui-panel-footer .ui-button:hover {
	color: #584620 !important;
	background: none !important;
	border: 0 !important;
}
.ui-panel-footer .ui-button span, .ui-panel-footer .ui-button:hover span {
	text-decoration: underline;
}
.no-content .ui-panel-content, .no-header .ui-panel-header {
	display: none;
}

.button-align-right{
	float: right;
}

.field-select{
	width: 180px;
}

.td-action{
	width: 10px;
}

.ui-breadcrumb{
 background-image: linear-gradient(to bottom, #f7eedd 30%, #f9f2e4 70%);
 border: #f7eedd;
}

.p-button {
	background: #fe7750;
	border: 1px solid #fe7750;
	margin-right: .5rem;
}

.p-button.p-button-text {
	color: #fe7750;
}

.p-calendar-w-btn .p-datepicker-trigger {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    background: #fe7750;
}

.p-calendar {
    height: 50%;
}

p-button-icon-only {
   	height: 35px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: #ffffff;
    border-color: #fe7750;
    color: #fe7750;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #fe7750;
}

.p-sidebar {
    background: #fe7750;
    padding: 1rem;
    border: 0 none;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: #ffffff;
    color: #495057;
}

.p-datatable .p-sortable-column.p-highlight {
    background: #f8f9fa;
    color: #fe7750;
}

.p-datatable .p-sortable-column.p-highlight:hover {
    background: #e9ecef;
    color: #fe7750;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #fe7750;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.2rem #fe7750;
    outline: 0 none;
}

.p-dropdown {
    background: #ffffff;
    border: 1px solid #fe7750;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-selectbutton .p-button.p-highlight {
    background: #fe7750;
    border-color: #fe7750;
    color: #ffffff;
}

.p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.3rem 0;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #fe7750;
    background: #fe7750;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #fe7750;
    background: #fe7750;
}

.column-filter--calendar .p-calendar .p-datepicker  {
	width: 400px;
}