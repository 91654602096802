/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */

@font-face {
	/* Ford Antenna Bold */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	/* Ford Antenna Medium */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	/* Ford Antenna Regular */
	font-family: Antenna;
	src: url("../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}
@font-face {	/* Ford Antenna Light */
	font-family: AntennaLight;
	src: url("../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 300;
}
@font-face {	/* Ford Antenna Light Italic */
	font-family: AntennaLight;
	src: url("../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
	font-style: italic;
	font-weight: 300;
}
@font-face {	/* Ford Antenna Condensed Bold */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 700;
}

@font-face {
	/* Ford Antenna Condensed Medium */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 500;
}

@font-face {
	/* Ford Antenna Condensed Regular */
	font-family: AntennaCond;
	src: url("../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"),
	url("../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
	font-style: normal;
	font-weight: 400;
}

/********************************* [END] Ford Fonts *********************************/

/* override PrimeNG theme font family */
$fontFamily: "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontFamilyAntenna: Antenna, Arial, Helvetica, sans-serif;
$fontFamilyAntennaLight: AntennaLight, Arial, Helvetica, sans-serif;
$fontFamilyAntennaCond: AntennaCond, Arial, Helvetica, sans-serif;


body{
	font-family: $fontFamily;
	line-height: 1.1;
	margin: 1px;
	background: #fdfaf4;
}

html, body, app-root{
	margin: 0px;
  padding: 0px;
  min-height: 100%;
  height: 100%;
  zoom: 0.98;
}

app-root{
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
}
#app-header{
	flex-shrink: 0;
	flex-basis: auto;
	margin: 0 0.5em 0.5em;
}
#app-content{
	height: auto;
	flex-grow: 2;
	margin: 0 1.5em 1em;
}

.ui-widget, label, legend, option {
	font-family: $fontFamily;
}

/* TODO: clean these up! */
label, legend {
	font-weight: 700;
}

fieldset label {
	font-weight: 400;
	/*font-size: 11px;*/
}

table .ui-widget-content td:first-child {
	overflow: visible;
}

/* then, where they should be used, bring in the Antenna fonts */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $fontFamilyAntennaCond;
	margin: 0 0 .5em;
}

h5, h6,
.h5, .h6 {
	font-family: $fontFamilyAntenna;
}

a, a:focus{
	color: #b39660;
	text-decoration: none;
}
a:hover{
	color: #584620;
}

p{
	margin: 0 0 10px;
}

hr{
	margin-bottom: 1em;
	border: 0;
	border-top: 1px solid #eee;
}

#app-branding h1 {
	font-family: $fontFamilyAntenna;
	font-weight: 700;
	font-size: 1.125rem;
	color: #584620;
}

#app-branding h1 a {
	text-decoration: none;
	&:hover,
	&:focus {
		cursor: pointer;
	}
}

.ui-menu {
	background: #b39660;
}

.ui-state-active {
	background: #b39660;
}


/* Header <40-kgnanara> */

#app-header #app-branding h1 {
	margin: 0;
	line-height: 1.5em;
	font-weight: 200;
	font-size: 30px;
	display: inline-block;
}

#app-header #app-branding{
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	// padding: 1em 0;
}
#app-header #app-branding #app-icon{
	align-self: center;
	text-align: center;
	color: #685b42;
	font-size: 13px;
}
#app-header #app-branding #app-name{
	flex-grow: 1;
	padding: 0 1em;
	align-self: center;
}
#app-header #app-branding #app-login{
	float: right;
	text-align: right;
	align-self: center;
}

#app-header #app-branding #app-icon img{
	display: inline-block;
}

#app-branding #app-login{display:block;}
.sidebar-app-login, .btn-sidebar{display: none;}

@media (min-width: 50px) and (max-width: 767px){
	.ui-menubar, #app-branding #app-login{display:none;}
	.ui-sidebar .ui-menubar, .sidebar-app-login, .btn-sidebar{display: block;}
	.quicklinks-list li a{padding: .15em;}
	#app-footer *{text-align: center;}
	#app-branding .logo{max-width: 90px; transition: max-width .5s ease-in;}

	// add para responsividade das telas


}

@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name h1{font-size: 18px; transition: font-size .5s ease-in;}
	#app-header #app-branding #app-name{text-align: center;}
	#app-header #app-branding .logo{max-width: 95px; transition: max-width .5s ease-in;}
	#app-header #app-branding #app-icon{align-self: flex-start !important;}
}

@media (min-width: 480px) {
	#app-header #app-branding #app-name h1{font-size: 20px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 100px; transition: max-width .5s ease-in;}
}

@media (min-width: 768px) {
	#app-header #app-branding #app-name h1{font-size: 22px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 105px; transition: max-width .5s ease-in;}
}

@media (min-width: 960px) {
	#app-header #app-branding #app-name h1{font-size: 24px;transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 110px; transition: max-width .5s ease-in;}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding #app-name h1{font-size: 26px; transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 115px; transition: max-width .5s ease-in;}
}
@media (min-width: 1287px) {
	#app-header #app-branding #app-name h1{font-size: 28px; transition: font-size .5s ease-in;}
	#app-header #app-branding .logo{max-width: 120px; transition: max-width .5s ease-in;}
}


/* Menu Bar <40-kgnanara> */
.ui-menubar {
	background: #b39660;
	border: none;
	padding: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}
.ui-menubar ul{
	line-height: .5 !important;
}

.ui-state-active {
	background: #584620;
}

.ui-menubar .ui-menuitem .ui-menuitem-link { /* First Level items */
	text-decoration: none;
	color: #fff;
	background: none !important;
	padding: 1em !important;
	border-radius: 0;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:hover{
	background-color: #584620 !important;
}
.ui-menubar .ui-menuitem .ui-menuitem-link:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active{
	background-color: #584620 !important;
	-moz-box-shadow: inset 0 0 7px #584620;
	-webkit-box-shadow: inset 0 0 7px #584620;
	box-shadow: inset 0 0 7px #584620;
}
.ui-menubar .ui-menuitem .ui-menuitem-link.ui-state-active{
	background-color: #584620 !important;
	-moz-box-shadow: inset 0 0 7px #584620;
	-webkit-box-shadow: inset 0 0 7px #584620;
	box-shadow: inset 0 0 7px #584620;
	transition: background-color .5s ease-in;
}
.ui-menubar .ui-menuitem.ui-menuitem-active, .ui-menubar .ui-menuitem.ui-menuitem-active .ui-menuitem-link{ /* First Level items (Active) */
	background: none;
}
.ui-menubar .ui-submenu-list{
	background: #fff;
	min-width: 10em;
}
.ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link { /* Second Level items */
	padding: .5em !important;
	color: #bc8b06;
	background: #fff;
}
.ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active .ui-menuitem-link { /* Second Level items (Active) */
	background: #eee !important;
	color: #bc8b06;
}

.ui-menubar .ui-menu-parent .ui-menu-child{
	background: #fff;
}
.ui-sidebar{
	padding: 100px;
	border: 3px;
	overflow-y: auto;
}
.ui-sidebar .ui-menubar .ui-menubar-root-list, .ui-sidebar .ui-menubar .ui-menubar-root-list>.ui-menuitem{
	display: block;
	width: 100%;
}
.ui-sidebar .ui-menubar .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid #584620;
}

.ui-sidebar .ui-menubar .ui-submenu-list .ui-menuitem .ui-menuitem-link{
	border-bottom: 1px solid #ccc;
}
.ui-sidebar .ui-menubar .ui-submenu-list, .ui-sidebar .ui-menubar .ui-menu-parent .ui-menu-child{
	position: static;
	width: 100%;
}
.ui-sidebar-close{
	z-index: 10000;
	position: absolute;
	color: #584620;
	background: #ccc;
	right: 0;
}

.btn-sidebar button{
	background: #b39660;
	border: 0;
	outline: none;
	padding: .45em .5em;
	font-size: 1.5em;
	color: #fff;
	// border-radius: 20%;

}
.ui-menubar .ui-menubar-custom{
	float: none;
	margin: 0;
	padding: 0;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a{
	color: #fff !important;
	display: block;
	width: 100%;
	background: none;
	padding: .75em;
	border-radius: 0;
	border-bottom: 1px solid #584620;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login a:hover, .ui-menubar .ui-menubar-custom .sidebar-app-login a:active, .ui-menubar .ui-menubar-custom .sidebar-app-login a:focus{
	color: #fff;
}
.ui-menubar .ui-menubar-custom .sidebar-app-login .fa{
	color: #fff;
}

/* [End] Menu Bar <40-kgnanara> */

h1,
.h1 {
	font-weight: normal;
	font-size: 2.4rem;
	margin-bottom: 10px;
}

h2,
.h2 {
	margin-bottom: 10px;
	//color: #336CA2;
}

h3,
.h3 {
	color: #645129;
}

h4,
.h4 {
	color: #584620;
}

h5,
.h5 {
	color: #584620;
}

h6,
.h6 {
	color: #584620;
}

.ui-datatable th.ui-state-default.ui-state-active {
	background: #584620;
}

.ui-datatable th.ui-state-default {
	background: #b39660;
	color: #fff;
}

.ui-datatable th.ui-sortable-column:not(.ui-state-active):hover {
	background: #b39660;
	color: #fff;
}

.ui-steps .ui-steps-title {
	font-family: $fontFamilyAntennaCond;
	font-size: 0.9rem;
}

/* primeng tables are fixed cell widths. gross. override back to default */
.ui-datatable table {
	table-layout: inherit;
}

.ui-datatable table th,
.ui-datatable table td {
	width: auto;
}

/* primeng doesn't have layout helpers. add them here */
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}
.items-center{
	display: flex;
	align-items: center;
}

/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
	padding: 5px;
}
.ui-autocomplete, .ui-autocomplete .ui-autocomplete-multiple-container.ui-inputtext{
	width: 100%;
}
.ui-autocomplete-token{
	margin: .125em;
	font-size: 100%;
}

input[type="text"], input[type="number"] {
	height: 34px;
}

fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}

.form-actions {
	border-top: 1px solid #e5e5e5;
	padding: 1em;
	margin: 0 -5px 5px -5px;
}

.header-user-ford-oval{
	color: #b39660;
	font-size: small;
	font-weight: bold;
}

.header-country-ford-oval{
	color: #b8b5a7;
	border: 1px;
	border-style: solid;
	border-color: #b8b5a7;
	padding: 1em;
	margin-right: 20px;
	font-size: xx-small;
}

:host ::ng-deep button {
	margin-right: .25em;
}

:host ::ng-deep .custom-toast .ui-toast-message {
	color: #584620;
	background: #FFFFFF;
}

:host ::ng-deep .custom-toast .ui-toast-close-icon {
	color: #FFFFFF;
}

.ui-toast .ui-toast-message-content {
	padding: .5em 1em;
	background-color: #FFFFFF;
}

/*  ------------ alteracao de paginas ---------------

codigo para alteracao das cores dos Botoes da pagina*/

.ui-widget-header .ui-button,
.ui-widget-content .ui-button,
.ui-widget.ui-button,
.ui-button {
	border: 1px solid #584620;
	color: #FFFFFF;
	background: #584620;
	-webkit-transition: background-color .2s;
	-moz-transition: background-color .2s;
	transition: background-color .2s;
	 margin-top: 0px;

	&:enabled:hover,
	&:focus {
		border: 1px solid #584620;
		background: #b39660;
		outline: 0 none;
		color: #FFFFFF;
	}

	&:enabled:active {
		border: 1px solid #584620;
		background: #b39660;
		color: #FFFFFF
	}
}

#app-header #app-branding {
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 20px;
	margin-right: 20px;
}

//Inicio table
	/* Data in responsive mode */
	.ui-datatable-reflow .ui-datatable-data > tr > td {
		width: 100% !important;
		text-align: left;
		border: 0;
		box-sizing: border-box;
		float: left;
		clear: left;
	}


	.ui-datatable-reflow .ui-datatable-data tr.ui-widget-content {
		border-left: 0;
		border-right: 0;
	}

	.ui-datatable-reflow .ui-datatable-data.ui-widget-content {
		border: 0;
	}

	/*Hide the headers in responsive mode*/
	.ui-datatable-reflow thead th {
		display: none !important;
	}

	/*Display the headers inline with the data in responsive mode */
	.ui-datatable-reflow .ui-datatable-data td .ui-column-title {
		padding: .4em;
		min-width: 30%;
		display: inline-block;
		margin: -.4em 1em -.4em -.4em;
		font-weight: bold;
	}

	//Table Footer
	.ui-paginator .ui-paginator-page.ui-state-active {
		background: #584620;
		color: #ffffff;
		border-color: #584620;
	}

	.ui-widget-header {
		// background: #b39660 0 0 repeat-x;
		background: linear-gradient(to bottom, #FFFFFF 0%, #f9f2e4 100%);
	}
	.ui-table .ui-table-thead > tr > th, .ui-table .ui-table-tfoot > tr > td {
		background: linear-gradient(to bottom, #FFFFFF 0%, #f9f2e4 100%);
	}

	.ui-table .ui-table-tbody > tr > td {
		border: 1px solid #f9f2e4;
		background-color: inherit;
	}

//Fim table

//Menu pequeno
.vector-short-menu .ui-widget-content {
	background-color: #b39660;
	z-index: 0px;

}
.vector-short-menu .ui-sidebar {
    padding: 1px;
	overflow-y: unset;
	height: auto;
}
//fim menu pequeno

.ui-state-highlight {
    border-color: #584620;
    background-color: #584620;
    color: #FFFFFF;
}

/*panel*/
.ui-panel.ui-widget {
    margin-top: 1px;
}
.ui-panel .ui-panel-titlebar {
    padding: .0em .0em;
}
.ui-panel .ui-widget-header, .ui-panel-header {
    background: linear-gradient(to bottom, #584620 0%, #b39660 100%) !important;
    border-color: #584620;
		width: 0px;
}
.ui-panel .ui-panel-footer {
    border-width: 1px 0 0;
    padding: .0em .0em;
    text-align: left;
}
/*fim panel*/

.ui-g-1, .ui-g-2, .ui-g-3,
.ui-g-4, .ui-g-5, .ui-g-6,
.ui-g-7, .ui-g-8, .ui-g-9,
.ui-g-10, .ui-g-11, .ui-g-12 {
    float: left;
    box-sizing: inherit;
    padding: .3em ;
}

.ui-panel .ui-panel-content {
    border: 0;
    background: 0;
    padding: .5em .75em .5em .75em;
}

.textareaEditor {
    width: 99%;
    margin-right: 60px;
}

//Calendar
.ui-datepicker.ui-widget .ui-datepicker-calendar td a.ui-state-active {
    background-color: #b39660;
    color: #f9f2e4;
}
//Fim Calendar

//selection
.ng-valid .ng-touched .ng-dirty{
	background-color: #b39660;
}
// end Selection

/* Select All None Links */
